import React from 'react'
import PropTypes from 'prop-types'
import Seo from '@salesforce/retail-react-app/app/components/seo'
import {Component} from '@salesforce/commerce-sdk-react/components'
import {Accordion, Divider, Box, Heading, useBreakpointValue} from '@chakra-ui/react'

/**
 * PDC Accordion : Child of PDCAccordionBlock
 * Displays content of a panel of PDCAccordionBlock
 *
 * @param {string} title - Title
 * @param {string} desc - Description
 * @param {boolean} generateStructuredData - Generate structured data
 * @param {object} regions - Regions
 * @returns {JSX.Element} - PDC Accordion component
 */
export const PDCAccordion = ({title, desc, generateStructuredData, regions}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }

    const isMobile = useBreakpointValue({base: true, md: false})

    // TODO : update structured data once the model is provided
    const structuredData = generateStructuredData
        ? JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'FAQPage',
              mainEntity: components.map((component) => {
                  return {
                      '@type': 'Question',
                      name: component?.data?.title,
                      acceptedAnswer: {
                          '@type': 'Answer',
                          text: component?.data?.desc
                      }
                  }
              })
          })
        : null

    return (
        <>
            {generateStructuredData && (
                <Seo>
                    <script type="application/ld+json">{structuredData}</script>
                </Seo>
            )}
            {!isMobile && (
                <Heading
                    dangerouslySetInnerHTML={{
                        __html: title
                    }}
                    as={'h2'}
                    size={'3xl'}
                    pb="36px"
                />
            )}
            <Divider color="borderGray" />
            <Box
                textStyle="bodyBase2"
                m={['48px 0 20px', null, '12px 0 20px']}
                dangerouslySetInnerHTML={{
                    __html: desc
                }}
            />
            {components && (
                <Accordion allowMultiple="true" as="ul" listStyleType="none">
                    {components.map((component, index) => {
                        return (
                            <li key={component?.id || index}>
                                <Component component={component} />
                            </li>
                        )
                    })}
                </Accordion>
            )}
        </>
    )
}

PDCAccordion.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    generateStructuredData: PropTypes.bool,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
