import React from 'react'
import PropTypes from 'prop-types'

import {
    Box,
    Text,
    useStyleConfig,
    Stack
} from '@salesforce/retail-react-app/app/components/shared/ui'
import Link from '@salesforce/retail-react-app/app/components/link'

export const PDCFooterLinksListCheckout = ({regions, title}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }

    const styles = useStyleConfig('Footer')
    const linkMarginBottom = {
        '&:not(:last-of-type)': {
            marginBottom: ' 10px'
        }
    }
    return (
        <Box>
            <Text
                role={'heading'}
                aria-level={2}
                variant={'bodyBase2'}
                fontSize={10}
                fontWeight={500}
                mb={'24px'}
                textTransform={'uppercase'}
            >
                {title}
            </Text>
            <Stack>
                {components.map((link, index) => (
                    <Link
                        key={index}
                        href={link.data.url}
                        {...styles.socialLink}
                        sx={linkMarginBottom}
                    >
                        {link.data.label}
                    </Link>
                ))}
            </Stack>
        </Box>
    )
}

export default PDCFooterLinksListCheckout

PDCFooterLinksListCheckout.propTypes = {
    title: PropTypes.string.isRequired,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
