import React from 'react'
import PropTypes from 'prop-types'
import {Image, Link, Text} from '@salesforce/retail-react-app/app/components/shared/ui'

/**
 * PDCFooterReinsuranceIcon component.
 * @typedef {Object} PDCFooterReinsuranceIconProps
 * @property {object} icon - Icon
 * @property {string} text - Displayed text
 * @property {string} url - Url of the link

 * @returns {React.ReactElement} - PDCFooterReinsuranceIcon component.
 */
export const PDCFooterReinsuranceIcon = ({icon, text, url}) => {
    return (
        <Link
            href={url}
            textAlign={'center'}
            textDecoration={'none'}
            display={'block'}
            mb={{base: '44px', lg: '34px'}}
        >
            <Image src={icon.url} alt={text} height={'34px'} margin={'auto'} />
            <Text
                variant={'bodyBase1'}
                mt={'11px'}
                textTransform={'uppercase'}
            >{`. ${text} .`}</Text>
        </Link>
    )
}

PDCFooterReinsuranceIcon.propTypes = {
    icon: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
}
