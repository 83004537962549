import React, {useState} from 'react'
import {Text, useStyleConfig, Stack} from '@salesforce/retail-react-app/app/components/shared/ui'
import LocaleSelector from '../../components/locale-selector'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'

import {getPathWithLocale} from '@salesforce/retail-react-app/app/utils/url'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import colors from '../../theme/foundations/colors'
import {PageContext, Component} from '@salesforce/commerce-sdk-react/components'
import {PDCFooterPaymentIcon} from '../../page-designer/assets'
import {PDCFooterPayments} from '../../page-designer/layouts'
import {usePage} from '@salesforce/commerce-sdk-react'

const PAGEDESIGNER_TO_COMPONENT_PAYMENTS = {
    'commerce_assets.pdcFooterPaymentIcon': PDCFooterPaymentIcon,
    'commerce_layouts.pdcFooterPayments': PDCFooterPayments
}
const FooterBottom = () => {
    const styles = useStyleConfig('Footer')
    const intl = useIntl()
    const {site, buildUrl} = useMultiSite()
    const {l10n} = site
    const supportedLocaleIds = l10n?.supportedLocales.map((locale) => locale.id)
    const location = useLocation()
    const isCheckout = /\/checkout$/.test(location?.pathname)
    const [contextValuePayments, setContextValuePayments] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_PAYMENTS
    })
    const {data: footerPage} = usePage({parameters: {pageId: 'footer'}})

    const footerPaymentsComponent = footerPage?.regions?.[0].components?.find(
        (comp) => comp?.typeId === 'commerce_layouts.pdcFooterPayments'
    )
    return (
        <Stack
            direction={{base: 'column', lg: 'row-reverse'}}
            justifyContent={'space-between'}
            alignItems={{base: '', lg: 'center'}}
            mx={'10px'}
            borderTop={{base: '', lg: `1px solid ${colors.borderGray}`}}
            marginTop={{base: '', lg: '44px'}}
            paddingY={{base: '', lg: '12px'}}
        >
            {isCheckout && footerPaymentsComponent ? (
                <PageContext.Provider value={contextValuePayments}>
                    <Component component={footerPaymentsComponent} />
                </PageContext.Provider>
            ) : (
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={{base: 'center', lg: 'flex-start'}}
                >
                    <Text
                        variant={'bodyBase1'}
                        paddingTop={{base: '12px', lg: '12px'}}
                        lineHeight={'24px'}
                        alignSelf={'flex-start'}
                    >
                        {/* TODO : DISPLAY DYNAMIC VALUE*/}
                        {'FR/€'}
                    </Text>
                    <LocaleSelector
                        {...styles.localeSelector}
                        selectedLocale={intl.locale}
                        locales={supportedLocaleIds}
                        onSelect={(newLocale) => {
                            // Update the `locale` in the URL.
                            const newUrl = getPathWithLocale(newLocale, buildUrl, {
                                disallowParams: ['refine']
                            })
                            window.location = newUrl
                        }}
                    />
                </Stack>
            )}
            <Text
                variant={'bodyBase1'}
                textTransform={'uppercase'}
                alignSelf={'flex-start'}
                lineHeight={'24px'}
                paddingTop={{base: '', lg: '12px'}}
                paddingBottom={{base: isCheckout ? '40px' : '24px', lg: '12px'}}
            >
                {intl.formatMessage({
                    id: 'footer.brand',
                    defaultMessage: 'vilebrequin©2024'
                })}
            </Text>
        </Stack>
    )
}

export default FooterBottom

FooterBottom.propTypes = {}
