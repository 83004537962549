import React from 'react'
import PropTypes from 'prop-types'

import {
    Box,
    SimpleGrid,
    useStyleConfig,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    AccordionIcon,
    useDisclosure
} from '@salesforce/retail-react-app/app/components/shared/ui'
import Link from '@salesforce/retail-react-app/app/components/link'
import LinksList from '@salesforce/retail-react-app/app/components/links-list'
import {WeChatModal} from '../../../components/wechat-modal'

export const PDCFooterLinksListMobile = ({regions, title, isSocial}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }
    const {isOpen: isWeChatOpen, onOpen: onWeChatOpen, onClose: onWeChatClose} = useDisclosure()

    const styles = useStyleConfig('Footer')

    return isSocial ? (
        <SimpleGrid columns={2} spacing={'16px'} my={'44px'} px={'10px'}>
            {components.map((link, index) =>
                link.data.label.toLowerCase() === 'wechat' ? (
                    <Box key={index} as={'span'}>
                        <Link {...styles.socialLink} onClick={onWeChatOpen}>
                            {link.data.label}
                        </Link>
                        <WeChatModal isOpen={isWeChatOpen} onClose={onWeChatClose} />
                    </Box>
                ) : (
                    <Link key={index} href={link.data.url} {...styles.socialLink}>
                        {link.data.label}
                    </Link>
                )
            )}
        </SimpleGrid>
    ) : (
        <AccordionItem px={'10px'} mt={'-1px'}>
            <AccordionButton
                flex={1}
                justifyContent={'space-between'}
                textTransform={'uppercase'}
                role={'heading'}
                aria-level={2}
            >
                {title}
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
                <LinksList
                    links={components.map((link) => ({
                        href: link.data.url,
                        text: link.data.label,
                        styles: {...styles.mobileLink}
                    }))}
                />
            </AccordionPanel>
        </AccordionItem>
    )
}

export default PDCFooterLinksListMobile

PDCFooterLinksListMobile.propTypes = {
    title: PropTypes.string.isRequired,
    isSocial: PropTypes.bool,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
