export default {
    baseStyle: {
        socialLink: {
            textDecoration: 'none',
            color: 'darkGray',
            textAlign: 'left'
        },
        languageSelector: {
            border: 'none'
        },
        mobileLink: {
            display: 'block',
            textDecoration: 'none',
            color: 'darkGray',
            textAlign: 'left',
            paddingBottom: ' 10px'
        }
    }
}
