import React from 'react'
import PropTypes from 'prop-types'

import {
    Box,
    Text,
    useStyleConfig,
    Stack,
    useDisclosure
} from '@salesforce/retail-react-app/app/components/shared/ui'
import Link from '@salesforce/retail-react-app/app/components/link'
import {WeChatModal} from '../../../components/wechat-modal'

export const PDCFooterLinksListDesktop = ({regions, title}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }
    const {isOpen: isWeChatOpen, onOpen: onWeChatOpen, onClose: onWeChatClose} = useDisclosure()

    const styles = useStyleConfig('Footer')
    const linkMarginBottom = {
        '&:not(:last-of-type)': {
            marginBottom: ' 10px'
        }
    }
    return (
        <Box>
            <Text
                role={'heading'}
                aria-level={2}
                variant={'bodyBase2'}
                fontSize={10}
                fontWeight={500}
                mb={'24px'}
                textTransform={'uppercase'}
            >
                {title}
            </Text>
            <Stack>
                {components.map((link, index) =>
                    link.data.label.toLowerCase() === 'wechat' ? (
                        <>
                            <Link
                                key={index}
                                {...styles.socialLink}
                                sx={linkMarginBottom}
                                onClick={onWeChatOpen}
                            >
                                {link.data.label}
                            </Link>
                            <WeChatModal
                                key={`modal_${index}`}
                                isOpen={isWeChatOpen}
                                onClose={onWeChatClose}
                            />
                        </>
                    ) : (
                        <Link
                            key={index}
                            href={link.data.url}
                            {...styles.socialLink}
                            sx={linkMarginBottom}
                        >
                            {link.data.label}
                        </Link>
                    )
                )}
            </Stack>
        </Box>
    )
}

export default PDCFooterLinksListDesktop

PDCFooterLinksListDesktop.propTypes = {
    title: PropTypes.string.isRequired,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
