import React, {useId} from 'react'
import PropTypes from 'prop-types'
import {Box, HStack, Flex, Text, useBreakpointValue} from '@chakra-ui/react'
import {Component} from '@salesforce/commerce-sdk-react/components'
import PDCTitle from '../../../components/pdc-title'
import {ResponsiveBynderVideo} from '../../../components/responsive-bynder-video'
import Seo from '@salesforce/retail-react-app/app/components/seo'
import usePDCResponsiveElement from '../../../hooks/use-pdc-responsive-element.js'

/**
 * PDC Home Full Screen Push Video component
 * @param {string} title - Title of the component
 * @param {string} titleHeadingLevel - Font size of the title
 * @param {string} titleFontColor - Font color of the title
 * @param {string} subTitle - Subtitle of the component
 * @param {string} smallVideo - Small video media ID
 * @param {string} smallVideoStructuredData - Small video structured data
 * @param {boolean} smallVideoAutoplay - Small video autoplay
 * @param {string} mediumVideo - Medium video media ID
 * @param {string} mediumVideoStructuredData - Medium video structured data
 * @param {boolean} mediumVideoAutoplay - Medium video autoplay
 * @param {string} largeVideo - Large video media ID
 * @param {string} largeVideoStructuredData - Large video structured data
 * @param {boolean} largeVideoAutoplay - Large video autoplay
 * @param {string} xlargeVideo - Extra large video media ID
 * @param {string} xlargeVideoStructuredData - Extra large video structured data
 * @param {boolean} xlargeVideoAutoplay - Extra large video autoplay
 * @param {string} xxlargeVideo - Extra extra large video media ID
 * @param {string} xxlargeVideoStructuredData - Extra extra large video structured data
 * @param {boolean} xxlargeVideoAutoplay - Extra extra large video autoplay
 * @param {string} txtHorizontalPos - Text horizontal position
 * @param {string} linksHorizontalPos - Links horizontal position
 * @param {object} regions - Regions of the component
 * @returns {JSX.Element} - PDC Home Full Screen Push Video component
 */
export const PDCHomeFullScreenPushVideo = ({
    title,
    titleHeadingLevel,
    titleFontColor,
    subTitle,
    smallVideo,
    smallVideoStructuredData,
    smallVideoAutoplay,
    mediumVideo,
    mediumVideoStructuredData,
    mediumVideoAutoplay,
    largeVideo,
    largeVideoStructuredData,
    largeVideoAutoplay,
    xlargeVideo,
    xlargeVideoStructuredData,
    xlargeVideoAutoplay,
    xxlargeVideo,
    xxlargeVideoStructuredData,
    xxlargeVideoAutoplay,
    txtHorizontalPos,
    linksHorizontalPos,
    regions
}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }

    const txtPosFlex = {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end'
    }

    // Choose video media ID based on breakpoint. If not set, we take the closest video media ID
    // from the previous breakpoints
    const videosPerBreakpoint = usePDCResponsiveElement({
        smallEl: smallVideo,
        mediumEl: mediumVideo,
        largeEl: largeVideo,
        xlargeEl: xlargeVideo,
        xxlargeEl: xxlargeVideo
    })

    // Choose video structured data contributed based on breakpoint if the video media ID is set for this breakpoint
    // otherwise we take the closest structured data from the previous breakpoints where the video is contributed
    const currentVideoStructuredData = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallVideoStructuredData,
            mediumEl: mediumVideoStructuredData,
            largeEl: largeVideoStructuredData,
            xlargeEl: xlargeVideoStructuredData,
            xxlargeEl: xxlargeVideoStructuredData,
            extraConditions: {
                conditionMd: mediumVideo,
                conditionLg: largeVideo,
                conditionXl: xlargeVideo,
                condition2xl: xxlargeVideo
            }
        })
    )
    // Choose autoplay contributed based on breakpoint if the video media ID is set for this breakpoint
    // otherwise we take the closest autoplay from the previous breakpoints where the video is contributed
    const currentAutoplay = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallVideoAutoplay,
            mediumEl: mediumVideoAutoplay,
            largeEl: largeVideoAutoplay,
            xlargeEl: xlargeVideoAutoplay,
            xxlargeEl: xxlargeVideoAutoplay,
            extraConditions: {
                conditionMd: mediumVideo,
                conditionLg: largeVideo,
                conditionXl: xlargeVideo,
                condition2xl: xxlargeVideo
            }
        })
    )

    const titleId = useId()

    return (
        <Box aria-describedby={titleId} as="section" position="relative">
            {currentVideoStructuredData && (
                <Seo>
                    <script type="application/ld+json">{currentVideoStructuredData}</script>
                </Seo>
            )}
            <ResponsiveBynderVideo mediaIds={videosPerBreakpoint} autoplay={currentAutoplay} />
            <Box p={['24px 10px', null, '38px 20px']}>
                <Flex
                    alignItems="center"
                    justifyContent={txtPosFlex[txtHorizontalPos]}
                    width="100%"
                >
                    <Flex
                        direction={'column'}
                        justifyContent={['center', null, txtPosFlex[txtHorizontalPos]]}
                        alignItems={[txtPosFlex[txtHorizontalPos], null, 'center']}
                        grow={1}
                        shrink={1}
                        maxWidth={'246px'}
                    >
                        <PDCTitle
                            title={title}
                            titleFontColor={titleFontColor}
                            titleHeadingLevel={titleHeadingLevel}
                            txtHorizontalPos={txtHorizontalPos}
                            id={titleId}
                            size="4xl"
                        />
                        <Text
                            dangerouslySetInnerHTML={{
                                __html: subTitle
                            }}
                            align={'center'}
                            variant="bodySmall"
                            color={'darkGray'}
                            mt={'20px'}
                        />
                    </Flex>
                </Flex>
                {components && components.length > 0 && (
                    <Flex
                        alignItems="center"
                        justifyContent={txtPosFlex[linksHorizontalPos]}
                        width="100%"
                        pt={'20px'}
                    >
                        <HStack alignItems={'center'} justifyContent={'center'} gap={10}>
                            {components.map((component, index) => {
                                component.displayConfig = 'transparent button'
                                component.defaultColor = 'fullBlack'
                                component.width = 'full'
                                return (
                                    <Component key={component?.id || index} component={component} />
                                )
                            })}
                        </HStack>
                    </Flex>
                )}
            </Box>
        </Box>
    )
}

PDCHomeFullScreenPushVideo.propTypes = {
    title: PropTypes.string.isRequired,
    titleHeadingLevel: PropTypes.string,
    titleFontColor: PropTypes.string,
    subTitle: PropTypes.string,
    smallVideo: PropTypes.string.isRequired,
    smallVideoStructuredData: PropTypes.string,
    smallVideoAutoplay: PropTypes.bool,
    mediumVideo: PropTypes.string,
    mediumVideoStructuredData: PropTypes.string,
    mediumVideoAutoplay: PropTypes.bool,
    largeVideo: PropTypes.string,
    largeVideoStructuredData: PropTypes.string,
    largeVideoAutoplay: PropTypes.bool,
    xlargeVideo: PropTypes.string,
    xlargeVideoStructuredData: PropTypes.string,
    xlargeVideoAutoplay: PropTypes.bool,
    xxlargeVideo: PropTypes.string,
    xxlargeVideoStructuredData: PropTypes.string,
    xxlargeVideoAutoplay: PropTypes.bool,
    txtHorizontalPos: PropTypes.string.isRequired,
    linksHorizontalPos: PropTypes.string.isRequired,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
